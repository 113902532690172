import React, { useState, useEffect } from "react";

interface PaginationPageProps {
  totalPages: number;
  currentPage: number;
  handlePrev: () => void;
  handleNext: () => void;
  handleSelectedPage: (selectedPage: number) => void;
}

const PaginationPage: React.FC<PaginationPageProps> = ({
  totalPages,
  currentPage,
  handlePrev,
  handleNext,
  handleSelectedPage,
}) => {
  const pagesToShow = 5;
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(Math.min(pagesToShow, totalPages));
  //   console.log(startPage, "start---");
  //   console.log(endPage, "endPage---");
  useEffect(() => {
    const newStartPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
    const newEndPage = Math.min(newStartPage + pagesToShow - 1, totalPages);
    setStartPage(newStartPage);
    setEndPage(newEndPage);
  }, [currentPage, totalPages]);

  const handlePageClick = (page: number) => {
    handleSelectedPage(page);
  };

  const handleEllipsisClick = (type: string) => {
    if (type === "start") {
      const newStartPage = Math.max(startPage - pagesToShow, 1);
      setStartPage(newStartPage);
      setEndPage(startPage - 1);
    } else {
      const newStartPage = Math.min(
        startPage + pagesToShow,
        totalPages - pagesToShow + 1
      );
      setStartPage(newStartPage);
      setEndPage(newStartPage + pagesToShow - 1);
    }
  };

  const renderEllipsis = (key: string, type: string) => (
    <li key={key}>
      <button
        onClick={() => handleEllipsisClick(type)}
        className="mx-2 relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-full text-center align-middle font-sans text-xs font-medium uppercase transition-all bg-white text-gray-900 border border-gray-300 hover:bg-[#1565B1]/10 active:bg-[#1565B1]/20"
      >
        ...
      </button>
    </li>
  );

  return (
    <nav aria-label="Page navigation example" className="flex justify-center">
      <ul className="inline-flex flex-wrap text-base">
        <li className="mb-2">
          <button onClick={handlePrev} disabled={currentPage === 1}>
            <p className="cursor-pointer flex items-center justify-center px-4 h-10 ms-0 leading-tight text-white bg-[#1565B1] border border-e-0 border-gray-300 rounded-l-full font-medium transition-all active:bg-[#1565B1]/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mx-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                ></path>
              </svg>
              Previous
            </p>
          </button>
        </li>
        {startPage > 1 && (
          <>
            <li>
              <button
                onClick={() => handlePageClick(1)}
                className={`mx-2 relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-full text-center align-middle font-sans text-xs font-medium uppercase transition-all ${
                  1 === currentPage
                    ? "bg-[#1565B1] text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                    : "bg-white text-gray-900 border border-gray-300 hover:bg-[#1565B1]/10 active:bg-[#1565B1]/20"
                }`}
              >
                1
              </button>
            </li>
            {startPage > 2 &&
              !Array.from(
                { length: endPage - startPage + 1 },
                (_, i) => startPage + i
              ).includes(currentPage) &&
              currentPage !== 1 &&
              currentPage < startPage && (
                <li>
                  <button
                    onClick={() => handlePageClick(currentPage)}
                    className={`mx-2 relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-full text-center align-middle font-sans text-xs font-medium uppercase transition-all ${"bg-[#1565B1] text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"}`}
                  >
                    {currentPage}
                  </button>
                </li>
              )}

            {startPage > 2 && renderEllipsis("start", "start")}
          </>
        )}
        {Array.from(
          { length: endPage - startPage + 1 },
          (_, i) => startPage + i
        ).map((pageValue) => (
          <li key={pageValue}>
            <button
              onClick={() => handlePageClick(pageValue)}
              className={`mx-2 relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-full text-center align-middle font-sans text-xs font-medium uppercase transition-all ${
                pageValue === currentPage
                  ? "bg-[#1565B1] text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                  : "bg-white text-gray-900 border border-gray-300 hover:bg-[#1565B1]/10 active:bg-[#1565B1]/20"
              }`}
            >
              <span className="Montserrat text-sm absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                {pageValue}
              </span>
            </button>
          </li>
        ))}

        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && renderEllipsis("end", "end")}
            {startPage > 2 &&
              !Array.from(
                { length: endPage - startPage + 1 },
                (_, i) => startPage + i
              ).includes(currentPage) &&
              currentPage !== 1 &&
              currentPage !== totalPages &&
              currentPage > endPage && (
                <li>
                  <button
                    onClick={() => handlePageClick(currentPage)}
                    className={`mx-2 relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-full text-center align-middle font-sans text-xs font-medium uppercase transition-all ${"bg-[#1565B1] text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"}`}
                  >
                    {currentPage}
                  </button>
                </li>
              )}
            <li>
              <button
                onClick={() => handlePageClick(totalPages)}
                className={`mx-2 relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-full text-center align-middle font-sans text-xs font-medium uppercase transition-all ${
                  totalPages === currentPage
                    ? "bg-[#1565B1] text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                    : "bg-white text-gray-900 border border-gray-300 hover:bg-[#1565B1]/10 active:bg-[#1565B1]/20"
                }`}
              >
                {totalPages}
              </button>
            </li>
          </>
        )}

        <li>
          <button onClick={handleNext} disabled={currentPage === totalPages}>
            <p className="cursor-pointer flex items-center justify-center px-4 h-10 ms-0 leading-tight text-white bg-[#1565B1] border border-e-0 border-gray-300 rounded-r-full font-medium transition-all active:bg-[#1565B1]/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
              Next
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mx-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                ></path>
              </svg>
            </p>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default PaginationPage;
