import React, { FC } from "react";

export interface PricesProps {
  className?: string;
  price?: any;
  contentClass?: string;
}

const Prices: FC<PricesProps> = ({
  className = "",
  price = 0,
  contentClass = "py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium",
}) => {
  return (
    <div className={`${className}`}>
      {price ? (
        <>
          <div
            className={`flex items-center border-2 border-blue-500 rounded-lg ${contentClass}`}
          >
            <span
              className="text-blue-500 !leading-none "
              style={{ fontFamily: "Public Sans" }}
            >
              ₹ {price?.toFixed(2)}
            </span>
          </div>
        </>
      ) : (
        <> </>
      )}
    </div>
  );
};

export default Prices;
