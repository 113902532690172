import React, { FC } from "react";
import newsSvg from "images/subLetter.png";
import NcImage from "shared/NcImage/NcImage";
import Input from "shared/Input/Input";
export interface ProductCategoryProps {
  className?: string;
}
export interface CardCategoryData {
  name: string;
  img: string;
  Subheading?: string;
}

export interface SectionSliderSolutionsProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: CardCategoryData[];
}

const SectionNewsLetter: FC<SectionSliderSolutionsProps> = () => {
  return (
    <>
      <div className=" bg-[#E5F2FF] relative h-[201px]">
        <div className="  ">
          <NcImage src={newsSvg} className="w-[608px] h-[173px] absolute " />
        </div>
        <div className=" container flex  items-center justify-between  h-[201px]  ">
          <h1 className=" text-2xl  lg:text-4xl  lg:ml-36 font-extrabold	">
            Subscribe our Newsletter
          </h1>
          <div className="flex">
            <Input className="rounded-sm" placeholder=" Enter Your Email " />
            <button className="bg-black text-white px-2 rounded-sm">
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionNewsLetter;
