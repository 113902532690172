import Label from "components/Label/Label";
import React, { FC, useState, Fragment } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Radio from "shared/Radio/Radio";
import Select from "shared/Select/Select";
import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { AddAdress, AuthMe, UpdateAdress } from "../../redux/Me/meSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
interface Props {
  isActive: boolean;
  MeData: any;
  onCloseActive: () => void;
  onOpenActive: () => void;
  onSaveChangeAdress: (newAddress: any) => void;
}

const ShippingAddress: FC<Props> = ({
  MeData,
  isActive,
  onCloseActive,
  onOpenActive,
  onSaveChangeAdress,
}) => {
  // console.log(MeData?.data?.user_addresses, "user_addresses");
  const [selectedAddress, setSelectedAddress] = useState<any>(
    MeData?.data?.user_addresses[0]
  );
  const [addressToEdit, setAddressToEdit] = useState<any>(
    MeData?.data?.user_addresses[0] ?? {}
  );

  const validationSchema = Yup.object().shape({
    address_line1: Yup.string().required("Address Line 1 is required"),

    // address_line1: Yup.required("Address Line 1 is required"),
  });
  const dispatch = useDispatch();
  const [loading, SetLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }
  const formik = useFormik({
    initialValues: {
      id: null,
      address_line1: "",
      address_line2: "",
      area: "",
      city: "",
      state: "",
      country: "india",
      pin_code: "",
    },
    validationSchema,
    onSubmit: (values: any) => {
      const addPayLoad = {
        ...values,
        user_id: MeData?.data?.id,
        // country: "",
        // pin_code: "",
      };
      SetLoading(true);
      if (values?.id) {
        dispatch(UpdateAdress(values)).then((data: any) => {
          SetLoading(false);

          if (data?.payload?.UpdateAdressData?.success) {
            closeModal();
            dispatch(AuthMe());
            toast.success(data?.payload?.UpdateAdressData?.message ?? "", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
      } else {
        dispatch(AddAdress(addPayLoad)).then((data: any) => {
          SetLoading(false);

          if (data?.payload?.AddAdressData?.success) {
            closeModal();
            dispatch(AuthMe());
            toast.success(data?.payload?.AddAdressData?.message ?? "", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
      }

      // toast.success("User Login Successfully");

      // console.log(values, "values");
    },
  });
  const handleSaveAndNext = () => {
    onCloseActive();
    setAddressToEdit(selectedAddress);
    onSaveChangeAdress(selectedAddress);
  };
  const handleOpenNewAddress = () => {
    setIsOpen(true);
  };
  const handleEditClick = (address: any) => {
    setIsOpen(true);
    formik.setValues({
      address_line1: address.address_line1 || "",
      address_line2: address.address_line2 || "",
      area: address.area || "",
      city: address.city || "",
      state: address.state || "",
      country: address.country || "",
      id: address.id || "",
      pin_code: address.pin_code || "",
    });
  };
  const renderShippingAddress = () => {
    return (
      <>
        {/* <button onClick={() => setIsOpen(true)}>Open dialog</button> */}
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <form
                      className="grid grid-cols-1 gap-6"
                      // onSubmit={formik.handleSubmit}
                    >
                      <div className="grid grid-cols-12 gap-5">
                        <div className="col-span-6">
                          <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                              Address Line 1
                            </span>
                            <Input
                              type="text"
                              name="address_line1"
                              placeholder=""
                              className="mt-1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.address_line1}
                            />
                            {formik.touched.address_line1 &&
                            formik.errors.address_line1 ? (
                              <div className="text-red-500 text-sm mt-1">
                                {formik.errors.address_line1}
                              </div>
                            ) : null}
                          </label>
                        </div>
                        <div className="col-span-6">
                          <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                              Address Line 2
                            </span>
                            <Input
                              type="text"
                              name="address_line2"
                              placeholder=""
                              className="mt-1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.address_line2}
                            />
                            {formik.touched.address_line2 &&
                            formik.errors.address_line2 ? (
                              <div className="text-red-500 text-sm mt-1">
                                {formik.errors.address_line2}
                              </div>
                            ) : null}
                          </label>
                        </div>
                        <div className="col-span-6">
                          <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                              Area
                            </span>
                            <Input
                              type="text"
                              name="area"
                              placeholder=""
                              className="mt-1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.area}
                            />
                            {formik.touched.area && formik.errors.area ? (
                              <div className="text-red-500 text-sm mt-1">
                                {formik.errors.area}
                              </div>
                            ) : null}
                          </label>
                        </div>
                        <div className="col-span-6">
                          <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                              City
                            </span>
                            <Input
                              type="text"
                              name="city"
                              placeholder=""
                              className="mt-1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.city}
                            />
                            {formik.touched.city && formik.errors.city ? (
                              <div className="text-red-500 text-sm mt-1">
                                {formik.errors.city}
                              </div>
                            ) : null}
                          </label>
                        </div>
                        <div className="col-span-6">
                          <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                              State
                            </span>
                            <Input
                              type="text"
                              name="state"
                              placeholder=""
                              className="mt-1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.state}
                            />
                            {formik.touched.state && formik.errors.state ? (
                              <div className="text-red-500 text-sm mt-1">
                                {formik.errors.state}
                              </div>
                            ) : null}
                          </label>
                        </div>
                        <div className="col-span-6">
                          <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                              Country
                            </span>
                            <Select
                              name="country"
                              className="mt-1.5"
                              defaultValue="india"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.country}
                            >
                              <option value="india">India</option>
                              <option value="united_states">
                                United States
                              </option>
                              <option value="canada">Canada</option>
                              <option value="mexico">Mexico</option>
                              <option value="united_kingdom">
                                United Kingdom
                              </option>
                              <option value="germany">Germany</option>
                              <option value="france">France</option>
                              <option value="italy">Italy</option>
                              <option value="brazil">Brazil</option>
                              <option value="australia">Australia</option>
                            </Select>
                            {/* <Input
                              type="text"
                              name="country"
                              placeholder=""
                              className="mt-1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.country}
                            /> */}
                            {formik.touched.country && formik.errors.country ? (
                              <div className="text-red-500 text-sm mt-1">
                                {formik.errors.country}
                              </div>
                            ) : null}
                          </label>
                        </div>
                        <div className="col-span-6">
                          <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                              Pin code
                            </span>
                            <Input
                              type="text"
                              name="pin_code"
                              placeholder=""
                              className="mt-1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.pin_code}
                            />
                            {formik.touched.pin_code &&
                            formik.errors.pin_code ? (
                              <div className="text-red-500 text-sm mt-1">
                                {formik.errors.pin_code}
                              </div>
                            ) : null}
                          </label>
                        </div>
                      </div>
                      <div className="flex justify-center gap-5">
                        <ButtonSecondary
                          onClick={() => {
                            closeModal();
                          }}
                        >
                          Cancel
                        </ButtonSecondary>

                        {loading ? (
                          <>
                            <ButtonPrimary loading disabled>
                              Submit
                            </ButtonPrimary>
                          </>
                        ) : (
                          <>
                            <ButtonPrimary onClick={formik.handleSubmit}>
                              Submit
                            </ButtonPrimary>
                          </>
                        )}
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
          <div className="p-6 flex flex-col sm:flex-row items-start">
            <span className="hidden sm:block">
              <svg
                className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>

            <div className="sm:ml-8">
              <h3 className=" text-slate-700 dark:text-slate-300 flex ">
                <span className="uppercase"> SELECT ADDRESS</span>
                {/* <svg
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg> */}
              </h3>
              <div className="font-semibold mt-1 text-sm">
                <span className="">
                  <span className="ml-1">
                    {addressToEdit?.address_line1 ?? ""}
                  </span>
                  <span className="ml-1">
                    {addressToEdit?.address_line2 ?? ""}
                  </span>
                  <span className="ml-1">{addressToEdit?.state ?? ""}</span>
                  <span className="ml-1">{addressToEdit?.city ?? ""}</span>
                  <span className="ml-1">{addressToEdit?.country ?? ""}</span>
                </span>
              </div>
            </div>
            <ButtonSecondary
              sizeClass="py-2 px-4 "
              fontSize="text-sm font-medium"
              className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
              onClick={onOpenActive}
            >
              Change
            </ButtonSecondary>
          </div>
          <div
            className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
              isActive ? "block" : "hidden"
            }`}
          >
            <div className="grid grid-cols-12 gap-5">
              {MeData?.data?.user_addresses?.map((value: any) => {
                const isSelected = selectedAddress?.id === value.id; // Check if the address is selected

                return (
                  <div className="col-span-6" key={value?.id}>
                    {" "}
                    <div
                      className={`max-w-sm rounded overflow-hidden shadow-lg ${
                        isSelected ? "border-2 border-blue-500" : ""
                      }`}
                      onClick={() => setSelectedAddress(value)}
                    >
                      <div className="px-6 py-4">
                        <div>
                          <div className="grid grid-cols-12">
                            <div className="col-span-9">
                              <div className="font-bold text-xl mb-2">
                                {value?.address_line1 ?? ""}
                              </div>
                            </div>
                            <div className="col-span-3">
                              <div className="flex justify-between">
                                <button
                                  className="text-blue-500 hover:text-blue-700"
                                  onClick={() => handleEditClick(value)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M 19.171875 2 C 18.448125 2 17.724375 2.275625 17.171875 2.828125 L 16 4 L 20 8 L 21.171875 6.828125 C 22.275875 5.724125 22.275875 3.933125 21.171875 2.828125 C 20.619375 2.275625 19.895625 2 19.171875 2 z M 14.5 5.5 L 3 17 L 3 21 L 7 21 L 18.5 9.5 L 14.5 5.5 z"></path>
                                  </svg>
                                </button>

                                <button className="text-red-500 hover:text-red-700">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 30 30"
                                  >
                                    <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="text-gray-700 text-sm">
                          {value?.address_line2 ?? ""} {value?.area ?? ""}{" "}
                          {value?.city ?? ""} {value?.state ?? ""}{" "}
                          {value?.country}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {/* ============ */}
            {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">First name</Label>
              <Input className="mt-1.5" defaultValue="Cole" />
            </div>
            <div>
              <Label className="text-sm">Last name</Label>
              <Input className="mt-1.5" defaultValue="Enrico " />
            </div>
          </div> */}

            {/* ============ */}
            {/* <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
            <div className="flex-1">
              <Label className="text-sm">Address</Label>
              <Input
                className="mt-1.5"
                placeholder=""
                defaultValue={"123, Dream Avenue, USA"}
                type={"text"}
              />
            </div>
            <div className="sm:w-1/3">
              <Label className="text-sm">Apt, Suite *</Label>
              <Input className="mt-1.5" defaultValue="55U - DD5 " />
            </div>
          </div> */}

            {/* ============ */}
            {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">City</Label>
              <Input className="mt-1.5" defaultValue="Norris" />
            </div>
            <div>
              <Label className="text-sm">Country</Label>
              <Select className="mt-1.5" defaultValue="United States ">
                <option value="United States">United States</option>
                <option value="United States">Canada</option>
                <option value="United States">Mexico</option>
                <option value="United States">Israel</option>
                <option value="United States">France</option>
                <option value="United States">England</option>
                <option value="United States">Laos</option>
                <option value="United States">China</option>
              </Select>
            </div>
          </div> */}

            {/* ============ */}
            {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">State/Province</Label>
              <Input className="mt-1.5" defaultValue="Texas" />
            </div>
            <div>
              <Label className="text-sm">Postal code</Label>
              <Input className="mt-1.5" defaultValue="2500 " />
            </div>
          </div> */}

            {/* ============ */}
            {/* <div>
            <Label className="text-sm">Address type</Label>
            <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
              <Radio
                label={`<span className="text-sm font-medium">Home <span className="font-light">(All Day Delivery)</span></span>`}
                id="Address-type-home"
                name="Address-type"
                defaultChecked
              />
              <Radio
                label={`<span className="text-sm font-medium">Office <span className="font-light">(Delivery <span className="font-medium">9 AM - 5 PM</span>)</span> </span>`}
                id="Address-type-office"
                name="Address-type"
              />
            </div>
          </div> */}

            {/* ============ */}
            <div className="flex flex-col gap-3 sm:flex-row pt-6">
              <ButtonPrimary
                className=" shadow-none text-sm "
                onClick={handleOpenNewAddress}
              >
                Add New Address
              </ButtonPrimary>
              <ButtonPrimary
                className=" shadow-none"
                onClick={handleSaveAndNext}
              >
                Save and next to Payment
              </ButtonPrimary>
              <ButtonSecondary
                className="mt-3 sm:mt-0 sm:ml-3"
                onClick={onCloseActive}
              >
                Cancel
              </ButtonSecondary>
            </div>
          </div>
          <ToastContainer />
        </div>
      </>
    );
  };
  return renderShippingAddress();
};

export default ShippingAddress;
