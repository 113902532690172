import React, { FC } from "react";
// import HeaderFilterSection from "components/HeaderFilterSection";
// import ProductCard from "components/ProductCard";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Product } from "data/data";
import TrendingProduct from "components/TrendingProduct";
// import NcProductCard from "components/NcProductCard";
import FadeInUpOnScroll from "components/Animation/FadeInUpOnScroll";
// import { useItemMasterData } from "api/inventory/useItemMasterData";
import ProductCard from "components/ProductCard";
import usePopularProductData from "api/inventory/usePopularProduct";

//
export interface SectionGridFeatureItemsProps {
  data?: Product[];
}

const SectionGridFeatureItems: FC<SectionGridFeatureItemsProps> = () => {
  const payLoad = {
    page: 1,
  };
  const { PopularProductData, isLoading: isLoadingItemMaster } =
    usePopularProductData();
  const { data } = PopularProductData ?? [];

  return (
    <div className="nc-SectionGridFeatureItems relative">
      {/* <HeaderFilterSection /> */}

      <TrendingProduct />
      <div className={`grid grid-cols-4 gap-5 `}>
        {isLoadingItemMaster &&
          Array.from({ length: 8 }).map((_, index) => (
            <div key={index} className="flex flex-col gap-4 w-52">
              <div className="skeleton h-52 w-full"></div>
              <div className="skeleton h-4 w-full"></div>
              <div className="skeleton h-4 w-28"></div>
            </div>
          ))}
      </div>

      <div className={`grid grid-cols-12 gap-5 `}>
        {data?.slice(0, 8)?.map((item: any, index: number) => (
          <div className="col-span-12 lg:col-span-3" key={index}>
            <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
              <ProductCard data={item} key={index} />
            </FadeInUpOnScroll>
          </div>
        ))}
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Show me more</ButtonPrimary>
      </div> */}
    </div>
  );
};

export default SectionGridFeatureItems;
