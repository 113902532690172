import axios from "axios";
import { API_ENDPOINTS } from "./apiEndPoints";

export const baseUrl = process.env.REACT_APP_BASE_URL;
export const token = JSON.parse(localStorage.getItem("token"));
export const fetchCMS = async () => {
  const response = await axios.get(`${baseUrl}${API_ENDPOINTS.cms}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const fetchItemMaster = async () => {
  const response = await axios.get(`${baseUrl}${API_ENDPOINTS.itemMaster}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const fetchCategoriesByProduct = async (slug) => {
  const response = await axios.get(
    `${baseUrl}${API_ENDPOINTS?.ProductByCategory}/${slug}`
  );
  return response.data;
};
export const fetchProductBySlug = async (slug) => {
  const response = await axios.get(
    `${baseUrl}${API_ENDPOINTS?.ProductBySlug}/${slug}`
  );
  return response.data;
};
export const pageBySlug = async (slug) => {
  const response = await axios.get(
    `${baseUrl}${API_ENDPOINTS?.PageBySlug}/${slug}`
  );
  return response.data;
};
export const GetSettings = async () => {
  const response = await axios.get(`${baseUrl}${API_ENDPOINTS?.Settings}`);
  return response.data;
};
