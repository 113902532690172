import ProductCategoryCard from "components/CardCategories/ProductCategoryCard";
import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import department1Png from "images/collections/p1.png";
import department2Png from "images/collections/p2.png";
import department3Png from "images/collections/p5.png";
import department4Png from "images/collections/p4.png";
import department5Png from "images/collections/p5.png";
import department6Png from "images/collections/p6.png";
import department7Png from "images/collections/p3.png";
import department8Png from "images/collections/p5.png";
import department9Png from "images/collections/p2.png";
import FadeInUpOnScroll from "components/Animation/FadeInUpOnScroll";
import useCategoryData from "api/category/useCategoryData";

export interface ProductCategoryProps {
  className?: string;
}
export interface CardCategoryData {
  name: string;
  img: string;
  Subheading?: string;
}
const ProductCategoryData: CardCategoryData[] = [
  {
    name: "Dome CCTV Cameras",

    img: department1Png,
    Subheading: "(5 Products)",
  },
  {
    name: "Bullet CCTV Cameras",

    img: department2Png,
    Subheading: "(5 Products)",
  },
  {
    name: "C-Mount CCTV Cameras",

    img: department3Png,
    Subheading: "(45 Products)",
  },
  {
    name: "PTZ Pan Tilt & Zoom Cameras",

    img: department4Png,
    Subheading: "(5 Products)",
  },
  {
    name: "Day/Night CCTV Cameras",

    img: department5Png,
    Subheading: "(5 Products)",
  },
  {
    name: "Infrared/night vision CCTV Camera",

    img: department6Png,
    Subheading: "(5 Products)",
  },
  {
    name: "Network/IP CCTV Cameras",

    img: department7Png,
    Subheading: "(5 Products)",
  },
  {
    name: "Wireless CCTV Cameras",

    img: department8Png,
    Subheading: "(5 Products)",
  },
  {
    name: "High Definition (HD) CCTV Cameras",

    img: department9Png,
    Subheading: "(5 Products)",
  },
];

export interface SectionSliderSolutionsProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: CardCategoryData[];
}

const SectionProductCategory: FC<SectionSliderSolutionsProps> = ({
  data = ProductCategoryData,
}) => {
  const { CategoryData, isLoading } = useCategoryData();
  // console.log(CategoryData?.data, "cate");
  return (
    <>
      <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
        <Heading>Products Category</Heading>
      </FadeInUpOnScroll>
      <div className={`grid grid-cols-4 gap-5 `}>
        {isLoading &&
          Array.from({ length: 8 })?.map((_, index) => (
            <div key={index} className="flex flex-col gap-4 w-52">
              <div className="skeleton h-12 w-full"></div>
              <div className="skeleton h-4 w-full"></div>
              <div className="skeleton h-4 w-28"></div>
            </div>
          ))}
      </div>
      <div className="grid grid-cols-12 gap-5">
        {CategoryData?.data?.map((item: any, index: any) => (
          <div
            className="  col-span-12 md:col-span-4 lg:col-span-4"
            key={index}
          >
            <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
              <ProductCategoryCard
                featuredImage={item.image1}
                name={item.name}
                Subheading={""}
              />
            </FadeInUpOnScroll>
          </div>
        ))}
      </div>
    </>
  );
};

export default SectionProductCategory;
