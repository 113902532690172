// src/utils/isAuthenticated.ts

// Function to check if the user is authenticated
export const isAuthenticated = (): boolean => {
  // Get the token from localStorage
  const token = localStorage.getItem("token");

  // Check if the token exists
  if (!token) {
    return false;
  }

  // Optionally, you can add more checks to validate the token
  // For example, you could decode the token and check its expiration date
  // Here, we'll assume that the presence of a token means the user is authenticated

  return true;
};
