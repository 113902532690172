import React, { FC } from "react";
// import Button from "shared/Button/Button";
// import NcImage from "shared/NcImage/NcImage";

export interface ProductCategoryProps {
  className?: string;
  featuredImage?: string;
  name?: string;
  Subheading?: string;
}

const NewsArticleCard: FC<ProductCategoryProps> = ({
  featuredImage = "",
  Subheading = "",
  name = "",
}) => {
  return (
    <div className="py-2">
      <div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img
          className="rounded-t-lg w-full h-[270px]  object-fill"
          src={featuredImage}
          alt=""
        />

        <div className="p-5">
          <div className="h-20">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white h-24 line-clamp-2">
              {name}
            </h5>
          </div>
          <div className="h-16">
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 h-22 line-clamp-2">
              {Subheading}
            </p>
          </div>
          <button className="flex items-center border  rounded-md px-2">
            Read More{" "}
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsArticleCard;
