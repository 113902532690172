import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import facebook from "images/socials/facebook.svg";
// import twitter from "images/socials/twitter.svg";
// import telegram from "images/socials/telegram.svg";
import youtube from "images/socials/youtube.svg";

export interface SocialsList1Props {
  className?: string;
}

const socials: SocialType[] = [
  {
    name: "Facebook",
    icon: facebook,
    href: "https://www.facebook.com/people/MEET-Solution-Incorporation/100057427150199/?",
  },
  {
    name: "Instagram",
    icon: "https://i.pinimg.com/736x/0a/1e/6f/0a1e6f9872388e41f03f6886654a8235.jpg",

    href: "https://www.instagram.com/meet_solution_incorporation_?igsh=MW51Y2hhZDdxMjBoZA%3D%3D",
  },
  {
    name: "Youtube",
    icon: youtube,
    href: "https://www.youtube.com/@meetsolutionincorporation7595",
  },
  // { name: "Facebook", icon: facebook, href: "#" },
  // { name: "Youtube", icon: youtube, href: "#" },
  // { name: "Telegram", icon: telegram, href: "#" },
  // { name: "Twitter", icon: twitter, href: "#" },
];

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-3" }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
      >
        <div className="flex-shrink-0 w-5 ">
          <img src={item.icon} alt="" />
        </div>
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {socials.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
