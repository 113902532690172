import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";

export interface ProductCategoryProps {
  className?: string;
  featuredImage?: string;
  name?: string;
  Subheading?: string;
}

const ProductCategoryCard: FC<ProductCategoryProps> = ({
  className = "",
  featuredImage = "",
  name = "",
  Subheading = "",
}) => {
  return (
    <div className="py-2">
      <div className="grid grid-cols-12">
        <div className="col-span-4">
          <NcImage
            containerClassName={`flex-shrink-0 w-auto h-auto   rounded-lg mr-4 overflow-hidden`}
            src={
              featuredImage
                ? featuredImage
                : "https://t4.ftcdn.net/jpg/03/76/40/81/360_F_376408140_kiazgwOvkEy0e50oxgF5kllIl7j2q1SQ.jpg"
            }
          />
        </div>
        <div className="col-span-8 py-2">
          <h1 className="text-lg font-medium	"> {name}</h1>
          <h2 className="text-gray-500"> {Subheading} </h2>
        </div>
      </div>
    </div>
  );
};

export default ProductCategoryCard;
