import Label from "components/Label/Label";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
// import { Product, PRODUCTS } from "data/data";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import ContactInfo from "./ContactInfo";
import PaymentMethod from "./PaymentMethod";
import ShippingAddress from "./ShippingAddress";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import NcImage from "shared/NcImage/NcImage";
import useCartActions from "utils/cartUtils";
import { PostSalesOrder } from "containers/store";
import moment from "moment";

const CheckoutPage = () => {
  const [tabActive, setTabActive] = useState<
    "ContactInfo" | "ShippingAddress" | "PaymentMethod"
  >("ShippingAddress");

  const handleScrollToEl = (id: string) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element?.scrollIntoView({ behavior: "smooth" });
    }, 80);
  };
  const [loadingConfirOrder, setLoadingConfirmOrder] = useState(false);
  const cartItems = useSelector(
    (state: RootState) => state.persistedReducer.cart
  );
  const MeStore: any = useSelector(
    (state: RootState) => state.persistedReducer.me.meData
  );
  const dispatch = useDispatch();
  const DefaultCompanyStore: any = useSelector(
    (state: RootState) => state.persistedReducer.me.defaultCompanyData
  );
  const totalTax = cartItems.items?.reduce((total: number, item: any) => {
    const itemTax =
      Number(item.cgst_amount ?? 0) +
      Number(item.sgst_amount ?? 0) +
      Number(item.igst_amount ?? 0);
    return total + itemTax;
  }, 0);
  const [currentaddress, setCurrentaddressAddress] = useState<any>(
    MeStore?.data?.user_addresses[0] ?? {}
  );
  // console.log(currentaddress?.state, "currentaddress");
  console.log(MeStore, "MeStore");
  console.log(cartItems, "cartItems");
  const { totalPrice, subTotal } = cartItems;
  const [finalTotal, setFinalTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const calulateTax = () => {
    console.log(currentaddress?.state?.toLowerCase(), "c");
    console.log(DefaultCompanyStore?.data?.state?.toLowerCase(), "d");
    console.log(
      currentaddress?.state?.toLowerCase() ==
        DefaultCompanyStore?.data?.state?.toLowerCase(),
      "ERR"
    );
    if (
      currentaddress?.state?.toLowerCase() ==
      DefaultCompanyStore?.data?.state?.toLowerCase()
      // currentaddress?.state?.toLowerCase() == "gujarat" &&
      // DefaultCompanyStore?.data?.state?.toLowerCase() == "gujarat"
    ) {
      const tax = cartItems.items?.reduce((total: number, item: any) => {
        const itemTax =
          Number(item.cgst_amount ?? 0) + Number(item.sgst_amount ?? 0);

        return total + itemTax;
      }, 0);
      setTax(tax);
      setFinalTotal(Number(subTotal) + Number(tax));
      // Your code here if the state matches "gujarat" for both currentAddress and MeStore
    } else {
      const tax = cartItems.items?.reduce((total: number, item: any) => {
        const itemTax = Number(item.igst_amount ?? 0);
        return total + itemTax;
      }, 0);
      setTax(tax);
      setFinalTotal(Number(subTotal) + Number(tax));
    }
  };

  const { increment, decrement, remove } = useCartActions();
  const renderProduct = (item: any) => {
    const { image, price, name, quantity, id } = item;

    const handleIncrement = () => {
      increment(id);
    };
    const handleDecrement = () => {
      decrement(id);
    };
    const handleRemoveProduct = () => {
      remove(id);
    };

    return (
      <div
        key={item?.id}
        className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0"
      >
        <div className="relative h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <NcImage
            src={image ?? ""}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
          <Link
            to={`/product-detail/${id}`}
            className="absolute inset-0"
          ></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div>
              <h3 className="text-base font-semibold mb-3">
                <Link to={`/product-detail/${id}`}>{name}</Link>
              </h3>
            </div>
            <div className="flex justify-between items-center">
              <div className="hidden sm:block text-center relative">
                <NcInputNumber
                  className="relative z-10"
                  defaultValue={quantity}
                  onIncrement={handleIncrement}
                  onDecrement={handleDecrement}
                />
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={price} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            <div
              className="relative cursor-pointer z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
              onClick={handleRemoveProduct}
            >
              <span>Remove</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const renderProduct = (item: Product, index: number) => {
  //   const { image, price, name } = item;

  //   return (
  //     <div key={index} className="relative flex py-7 first:pt-0 last:pb-0">
  //       <div className="relative h-36 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
  //         <img
  //           src={image}
  //           alt={name}
  //           className="h-full w-full object-contain object-center"
  //         />
  //         <Link to="/product-detail" className="absolute inset-0"></Link>
  //       </div>

  //       <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
  //         <div>
  //           <div className="flex justify-between ">
  //             <div className="flex-[1.5] ">
  //               <h3 className="text-base font-semibold">
  //                 <Link to="/product-detail">{name}</Link>
  //               </h3>
  //               <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
  //                 <div className="flex items-center space-x-1.5">
  //                   <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
  //                     <path
  //                       d="M7.01 18.0001L3 13.9901C1.66 12.6501 1.66 11.32 3 9.98004L9.68 3.30005L17.03 10.6501C17.4 11.0201 17.4 11.6201 17.03 11.9901L11.01 18.0101C9.69 19.3301 8.35 19.3301 7.01 18.0001Z"
  //                       stroke="currentColor"
  //                       strokeWidth="1.5"
  //                       strokeMiterlimit="10"
  //                       strokeLinecap="round"
  //                       strokeLinejoin="round"
  //                     />
  //                     <path
  //                       d="M8.35 1.94995L9.69 3.28992"
  //                       stroke="currentColor"
  //                       strokeWidth="1.5"
  //                       strokeMiterlimit="10"
  //                       strokeLinecap="round"
  //                       strokeLinejoin="round"
  //                     />
  //                     <path
  //                       d="M2.07 11.92L17.19 11.26"
  //                       stroke="currentColor"
  //                       strokeWidth="1.5"
  //                       strokeMiterlimit="10"
  //                       strokeLinecap="round"
  //                       strokeLinejoin="round"
  //                     />
  //                     <path
  //                       d="M3 22H16"
  //                       stroke="currentColor"
  //                       strokeWidth="1.5"
  //                       strokeMiterlimit="10"
  //                       strokeLinecap="round"
  //                       strokeLinejoin="round"
  //                     />
  //                     <path
  //                       d="M18.85 15C18.85 15 17 17.01 17 18.24C17 19.26 17.83 20.09 18.85 20.09C19.87 20.09 20.7 19.26 20.7 18.24C20.7 17.01 18.85 15 18.85 15Z"
  //                       stroke="currentColor"
  //                       strokeWidth="1.5"
  //                       strokeLinecap="round"
  //                       strokeLinejoin="round"
  //                     />
  //                   </svg>

  //                   <span>{`Black`}</span>
  //                 </div>
  //                 <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
  //                 <div className="flex items-center space-x-1.5">
  //                   <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
  //                     <path
  //                       d="M21 9V3H15"
  //                       stroke="currentColor"
  //                       strokeWidth="1.5"
  //                       strokeLinecap="round"
  //                       strokeLinejoin="round"
  //                     />
  //                     <path
  //                       d="M3 15V21H9"
  //                       stroke="currentColor"
  //                       strokeWidth="1.5"
  //                       strokeLinecap="round"
  //                       strokeLinejoin="round"
  //                     />
  //                     <path
  //                       d="M21 3L13.5 10.5"
  //                       stroke="currentColor"
  //                       strokeWidth="1.5"
  //                       strokeLinecap="round"
  //                       strokeLinejoin="round"
  //                     />
  //                     <path
  //                       d="M10.5 13.5L3 21"
  //                       stroke="currentColor"
  //                       strokeWidth="1.5"
  //                       strokeLinecap="round"
  //                       strokeLinejoin="round"
  //                     />
  //                   </svg>

  //                   <span>{`2XL`}</span>
  //                 </div>
  //               </div>

  //               <div className="mt-3 flex justify-between w-full sm:hidden relative">
  //                 <select
  //                   name="qty"
  //                   id="qty"
  //                   className="form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
  //                 >
  //                   <option value="1">1</option>
  //                   <option value="2">2</option>
  //                   <option value="3">3</option>
  //                   <option value="4">4</option>
  //                   <option value="5">5</option>
  //                   <option value="6">6</option>
  //                   <option value="7">7</option>
  //                 </select>
  //                 <Prices
  //                   contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
  //                   price={price}
  //                 />
  //               </div>
  //             </div>

  //             <div className="hidden flex-1 sm:flex justify-end">
  //               <Prices price={price} className="mt-0.5" />
  //             </div>
  //           </div>
  //         </div>

  //         <div className="flex mt-auto pt-4 items-end justify-between text-sm">
  //           <div className="hidden sm:block text-center relative">
  //             <NcInputNumber className="relative z-10" />
  //           </div>

  //           <a
  //             href="##"
  //             className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
  //           >
  //             <span>Remove</span>
  //           </a>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const handleSeletedAdreess = (items: any) => {
    // console.log(items, "items");
    setCurrentaddressAddress(items);
    // setTimeout(() => {
    //   calulateTax();
    // }, 3000);
  };
  const renderLeft = () => {
    return (
      <div className="space-y-8">
        <div id="ContactInfo" className="scroll-mt-24">
          <ContactInfo
            MeData={MeStore}
            isActive={tabActive === "ContactInfo"}
            onOpenActive={() => {
              setTabActive("ContactInfo");
              handleScrollToEl("ContactInfo");
            }}
            onCloseActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
          />
        </div>

        <div id="ShippingAddress" className="scroll-mt-24">
          <ShippingAddress
            onSaveChangeAdress={handleSeletedAdreess}
            MeData={MeStore}
            isActive={tabActive === "ShippingAddress"}
            onOpenActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
            onCloseActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
          />
        </div>

        {/* <div id="PaymentMethod" className="scroll-mt-24">
          <PaymentMethod
            isActive={tabActive === "PaymentMethod"}
            onOpenActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
            onCloseActive={() => setTabActive("PaymentMethod")}
          />
        </div> */}
      </div>
    );
  };
  const handleConfirmOrder = () => {
    const payload = {
      client: MeStore?.data?.id,
      sale_order_item: cartItems?.items?.map((item: any) => {
        // console.log(item, "item");
        if (
          currentaddress?.state?.toLowerCase() ==
          DefaultCompanyStore?.data?.state?.toLowerCase()
        ) {
          return {
            pid: item?.id,
            qty: item?.quantity,
            rate: item?.price,
            total: Number(item?.quantity ?? 0) * Number(item?.price ?? 0),
            cgst: item?.cgst_amount,
            cgst_per: item?.cgst,
            sgst_per: item?.sgst,
            sgst: item?.sgst_amount,
            igst_per: 0,
            igst: 0,
            net_amount: Number(item?.quantity ?? 0) * Number(item?.price ?? 0),
          };
        } else {
          return {
            pid: item?.id,
            qty: item?.quantity,
            rate: item?.price,
            total: Number(item?.quantity ?? 0) * Number(item?.price ?? 0),
            cgst: 0,
            cgst_per: 0,
            sgst_per: 0,
            sgst: 0,
            igst_per: item?.igst,
            igst: item?.igst_amount,
            net_amount: Number(item?.quantity ?? 0) * Number(item?.price ?? 0),
          };
        }
      }),
      user_address: currentaddress?.id,
      order_from: "web",
      sub_total: subTotal?.toFixed(2) ?? 0,
      famount: finalTotal?.toFixed(2) ?? 0,
      g_total: tax?.toFixed(2) ?? 0,
      approved: "Approved",
      approved_datetime: moment().toISOString(),
      sales_order_date: moment().format("YYYY-MM-DD"),
    };
    setLoadingConfirmOrder(true);
    dispatch(PostSalesOrder(payload)).then((data: any) => {
      // console.log(data?.payload?.salesOrderData?.data?, "datasalesOrderData");
      const Locallink = `http://192.168.0.48:8001/pay-now/${data?.payload?.salesOrderData?.data?.sales?.uuid}`;
      const Livelink = ` https://api.meetsolution.in/pay-now/${data?.payload?.salesOrderData?.data?.sales?.uuid}`;
      if (data?.payload?.salesOrderData?.success) {
        window.open(Livelink, "_blank");
      }
      // console.log(Locallink, "link");
      setLoadingConfirmOrder(false);
    });
    console.log(payload, "PAYLOAD");
  };
  useEffect(() => {
    calulateTax();
  }, [cartItems, currentaddress?.state]);
  return (
    <div className="nc-CheckoutPage">
      <Helmet>
        <title>Checkout </title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Checkout
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to={"/#"} className="">
              Homepage
            </Link>

            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">Checkout</span>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row">
          <div className="flex-1">{renderLeft()}</div>

          <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>

          <div className="w-full lg:w-[36%] ">
            <h3 className="text-lg font-semibold">Order summary</h3>
            <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
              {cartItems.items.map((item) => renderProduct(item))}
              {/* {[PRODUCTS[0], PRODUCTS[2], PRODUCTS[3]].map(renderProduct)} */}
            </div>

            <div className="mt-10 pt-6 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">
              <div>
                <Label className="text-sm">Discount code</Label>
                <div className="flex mt-1.5">
                  <Input sizeClass="h-10 px-4 py-3" className="flex-1" />
                  <button className="text-neutral-700 dark:text-neutral-200 border border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 rounded-2xl px-4 ml-3 font-medium text-sm bg-neutral-200/70 dark:bg-neutral-700 dark:hover:bg-neutral-800 w-24 flex justify-center items-center transition-colors">
                    Apply
                  </button>
                </div>
              </div>

              <div className="mt-4 flex justify-between py-2.5">
                <span>Subtotal</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  ₹ {subTotal}
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>Shipping estimate</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  ₹0.00
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>Tax estimate</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {/* ₹{totalTax?.toFixed(2) ?? 0} */}₹{tax?.toFixed(2) ?? 0}
                </span>
              </div>
              <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                <span>Order total</span>
                <span>₹{finalTotal}</span>
              </div>
            </div>
            {loadingConfirOrder ? (
              <>
                <ButtonPrimary loading disabled className="mt-8 w-full">
                  Confirm order
                </ButtonPrimary>
              </>
            ) : (
              <>
                <ButtonPrimary
                  onClick={handleConfirmOrder}
                  className="mt-8 w-full"
                >
                  Confirm order
                </ButtonPrimary>
              </>
            )}

            {/* <ButtonPrimary href="/account-my-order" className="mt-8 w-full">
              Confirm order
            </ButtonPrimary> */}
            <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
              <p className="block relative pl-5">
                <svg
                  className="w-4 h-4 absolute -left-1 top-0.5"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 8V13"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.9945 16H12.0035"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Learn more{` `}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="##"
                  className="text-slate-900 dark:text-slate-200 underline font-medium"
                >
                  Taxes
                </a>
                <span>
                  {` `}and{` `}
                </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="##"
                  className="text-slate-900 dark:text-slate-200 underline font-medium"
                >
                  Shipping
                </a>
                {` `} infomation
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CheckoutPage;
