import React, { FC, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
// import Pagination from "shared/Pagination/Pagination";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import ProductCard from "components/ProductCard";
import TabFilters from "./TabFilters";
import PaginationPage from "shared/Pagination/PaginationPage";
import { useItemMasterData } from "api/inventory/useItemMasterData";

export interface OurProductCollectionProps {
  className?: string;
}

const OurProductCollection: FC<OurProductCollectionProps> = ({
  className = "",
}) => {
  const [page, setPage] = useState(1);
  const titleRef = useRef(null);

  const payLoad = {
    page: page,
  };
  const { ItemMasterData, isLoading: isLoadingItemMaster } =
    useItemMasterData(payLoad);
  const { data, meta } = ItemMasterData ?? [];
  // const { data, last_page } = ItemMasterData ?? [];
  // console.log(ItemMasterData, "ItemMasterData");
  const lastPage = meta?.last_page ?? "";
  // const lastPage = last_page ?? "";
  const handelSelectedPage = (value: any) => {
    // console.log(value, "values");
    if (titleRef.current && value) {
      const customScrollHeight = (titleRef?.current as HTMLElement)?.offsetTop;
      window.scrollTo({ top: customScrollHeight, behavior: "smooth" });
    }

    setPage(value);
  };

  const handleNext = () => {
    // console.log("EE");
    if (titleRef.current) {
      const customScrollHeight = (titleRef?.current as HTMLElement)?.offsetTop;
      window.scrollTo({ top: customScrollHeight, behavior: "smooth" });
    }

    setPage(() => page + 1);
    if (page >= lastPage) {
      setPage(1);
    }
  };

  const handlePrev = () => {
    if (titleRef.current) {
      const customScrollHeight = (titleRef?.current as HTMLElement)?.offsetTop;
      window.scrollTo({ top: customScrollHeight, behavior: "smooth" });
    }

    setPage(() => page - 1);
    if (page <= 1) {
      setPage(1);
    }
  };
  // const pages = Array.from({ length: lastPage }, (_, index) => index + 1);

  if (!data?.length && !isLoadingItemMaster) {
    return <h1 className="text-center py-10 text-2xl"> No Data Found </h1>;
  }

  return (
    <div
      className={`nc-PageCollection ${className}`}
      data-nc-id="PageCollection"
    >
      <Helmet>
        <title>Our Product </title>
      </Helmet>

      <div className="container py-5 lg:pb-10 lg:pt-10 ">
        <div className="space-y-5 lg:space-y-5">
          {/* HEADING */}
          <div className="max-w-screen-sm" ref={titleRef}>
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              Our Product
            </h2>
          </div>

          <hr className="border-slate-200 dark:border-slate-700" />
          <main>
            <TabFilters />

            {/* LOOP ITEMS */}
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
              {isLoadingItemMaster &&
                Array.from({ length: 12 }).map((_, index) => (
                  <div key={index} className="flex flex-col gap-4 w-52">
                    <div className="skeleton h-52 w-full"></div>
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-4 w-28"></div>
                  </div>
                ))}
            </div>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
              {data &&
                data.map((item: any, index: any) => (
                  <ProductCard data={item} key={index} />
                ))}
            </div>

            <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-center sm:items-center">
              {lastPage > 1 && (
                <PaginationPage
                  totalPages={lastPage}
                  currentPage={page}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  handleSelectedPage={handelSelectedPage}
                />
              )}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default OurProductCollection;
