// import React from "react";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionGridFeatureItems from "./SectionGridFeatureItems";
import SectionSliderSolutions from "components/SectionSliderCategories/SectionSliderSolutions";
import SectionProductCategory from "./SectionProductCategory";
import SectionNewsArticle from "./SectionNewsArtical";
import SectionNewsLetter from "./SectionNewsLetter";
import SectionSupport from "./SectionSupport";
import useCMSData from "api/cms/useCMSData";

function PageHome() {
  const { cmsData, isLoading } = useCMSData();

  console.log(cmsData, isLoading, "is CMSData");

  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* SECTION HERO */}
      <SectionHero2 />
      <div className="container space-y-12 my-12 lg:space-y-22 lg:my-22">
        <SectionProductCategory />
      </div>
      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        <SectionGridFeatureItems />
      </div>

      <div className="bg-[#EAE2DE]">
        <div className="container my-10 py-10">
          <SectionSliderSolutions />
        </div>
      </div>
      <div className="container">
        <SectionSupport />
      </div>
      <div className="container py-10">
        <SectionNewsArticle />
      </div>

      <SectionNewsLetter />
    </div>
  );
}

export default PageHome;
