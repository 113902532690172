import React, { FC, useEffect, useId } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
// import CardCategory2 from "components/CardCategories/CardCategory2";
import department1Png from "images/collections/S1.png";
import department2Png from "images/collections/S2.png";
import department3Png from "images/collections/S5.png";
import department4Png from "images/collections/S4.png";
import department5Png from "images/collections/S5.png";
import department6Png from "images/collections/S6.png";
import department7Png from "images/collections/S7.png";
import department8Png from "images/collections/S8.png";
import department9Png from "images/collections/S9.png";
import department10Png from "images/collections/S10.png";
import department11Png from "images/collections/S11.png";
// import { Link } from "react-router-dom";
// import CardCategory1 from "components/CardCategories/CardCategory1";
import SolutionCard from "components/CardCategories/SolutionCard";
import FadeInUpOnScroll from "components/Animation/FadeInUpOnScroll";

export interface CardCategoryData {
  name: string;

  img: string;
  color?: string;
}
const CATS: CardCategoryData[] = [
  {
    name: "Banking",

    img: department1Png,
    color: "bg-indigo-100",
  },
  {
    name: "Campus",

    img: department2Png,
    color: "bg-slate-100",
  },
  {
    name: "Hospitality / Health Care",

    img: department3Png,
    color: "bg-sky-100",
  },
  {
    name: "Industrial",

    img: department4Png,
    color: "bg-orange-100",
  },
  {
    name: "Oil & Gas",

    img: department5Png,
    color: "bg-orange-100",
  },
  {
    name: "Real Estate",

    img: department6Png,
    color: "bg-orange-100",
  },
  {
    name: "Retail",

    img: department7Png,
    color: "bg-orange-100",
  },
  {
    name: "Safe City",

    img: department8Png,
    color: "bg-orange-100",
  },
  {
    name: "Smart Traffic",

    img: department9Png,
    color: "bg-orange-100",
  },
  {
    name: "Transport",

    img: department10Png,
    color: "bg-orange-100",
  },
  {
    name: "Law Enforcement",

    img: department11Png,
    color: "bg-orange-100",
  },
];
export interface SectionSliderSolutionsProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: CardCategoryData[];
}

const SectionSliderSolutions: FC<SectionSliderSolutionsProps> = ({
  heading = "Our Solutions",
  subHeading = "",
  className = "",
  itemClassName = "",
  data = CATS,
}) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  useEffect(() => {
    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4,
        },
        1024: {
          gap: 20,
          perView: 3.4,
        },
        768: {
          gap: 20,
          perView: 3,
        },
        640: {
          gap: 20,
          perView: 2.3,
        },
        500: {
          gap: 20,
          perView: 1.4,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [UNIQUE_CLASS]);

  return (
    <div className={`nc-SectionSliderSolutions ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
          <Heading desc={subHeading} hasNextPrev>
            {heading}
          </Heading>
        </FadeInUpOnScroll>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {data.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                <FadeInUpOnScroll
                  defaultClassName="animated"
                  className="fadeIn"
                >
                  <SolutionCard
                    featuredImage={item.img}
                    name={item.name}
                    desc=""
                  />
                </FadeInUpOnScroll>
              </li>
            ))}
            {/* <li className={`glide__slide ${itemClassName}`}>
              <div
                className={`flex-1 relative w-full h-0 rounded-2xl overflow-hidden group aspect-w-1 aspect-h-1 bg-slate-100`}
              >
                <div>
                  <div className="absolute inset-y-6 inset-x-10 flex flex-col sm:items-center justify-center">
                    <div className="flex relative text-slate-900">
                      <span className="text-lg font-semibold ">
                        More collections
                      </span>
                      <svg
                        className="absolute left-full w-5 h-5 ml-2 rotate-45 group-hover:scale-110 transition-transform"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.0701 9.57L12.0001 3.5L5.93005 9.57"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M12 20.4999V3.66992"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                    <span className="text-sm mt-1 text-slate-800">
                      Show me more
                    </span>
                  </div>
                </div>
                <Link
                  to={"/"}
                  className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"
                ></Link>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderSolutions;
