import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the interface for CartItem
interface CartItem {
  id: number;
  name: string;
  price: number;
  quantity: number;
  image: any;
  cgst: number;
  sgst: number;
  igst: number;
  slug: any;
  net_amount: number;
  total: number;
  cgst_amount: number;
  sgst_amount: number;
  igst_amount: number;
}

// Define the interface for CartState
interface CartState {
  items: CartItem[];
  totalItems: number;
  subTotal: number;
  totalPrice: any;
  totalTax: number;
}

// Define initial state for CartState
const initialState: CartState = {
  items: [],
  totalItems: 0,
  totalPrice: 0,
  subTotal: 0,
  totalTax: 0,
};

// Utility function to update tax amounts
const calculateTaxAmounts = (item: CartItem) => {
  item.cgst_amount = (item.total * item.cgst) / 100;
  item.sgst_amount = (item.total * item.sgst) / 100;
  item.igst_amount = (item.total * item.igst) / 100;
  item.net_amount =
    item.total + item.cgst_amount + item.sgst_amount + item.igst_amount;
};

// Create cartSlice using createSlice from Redux Toolkit
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // Add item to cart or update quantity if already exists
    addToCart: (state, action: PayloadAction<CartItem>) => {
      const { id, quantity, price, cgst, sgst, igst } = action.payload;
      const existingItemIndex = state.items.findIndex((item) => item.id === id);

      if (existingItemIndex !== -1) {
        const existingItem = state.items[existingItemIndex];
        existingItem.quantity += quantity;
        existingItem.total = existingItem.quantity * price;
        calculateTaxAmounts(existingItem);
      } else {
        // New item, calculate taxes and add it to cart
        const total = price * quantity;
        const newItem: CartItem = {
          ...action.payload,
          total,
          cgst_amount: 0,
          sgst_amount: 0,
          igst_amount: 0,
          net_amount: 0,
        };
        calculateTaxAmounts(newItem);
        state.items.push(newItem);
      }

      // Update total items and total price
      state.totalItems = state.items.reduce(
        (total, item) => total + item.quantity,
        0
      );
      state.totalPrice = state.items
        .reduce((total, item) => total + item.net_amount, 0)
        .toFixed(2);
      state.subTotal = state.items.reduce(
        (total, item) => total + item.price,
        0
      );
    },

    // Remove item from cart by ID
    removeFromCart: (state, action: PayloadAction<number>) => {
      const itemIdToRemove = action.payload;
      state.items = state.items.filter((item) => item.id !== itemIdToRemove);

      // Update total items and total price
      state.totalItems = state.items.reduce(
        (total, item) => total + item.quantity,
        0
      );
      state.totalPrice = state.items
        .reduce((total, item) => total + item.net_amount, 0)
        ?.toFixed(2);
      state.subTotal = state.items.reduce(
        (total, item) => total + item.price,
        0
      );
    },

    // Increment quantity of an item in the cart
    incrementQuantity: (state, action: PayloadAction<number>) => {
      const itemIdToIncrement = action.payload;
      const itemToIncrement = state.items.find(
        (item) => item.id === itemIdToIncrement
      );
      if (itemToIncrement) {
        itemToIncrement.quantity += 1;
        itemToIncrement.total =
          itemToIncrement.quantity * itemToIncrement.price;
        calculateTaxAmounts(itemToIncrement);
      }

      // Update total price
      state.totalPrice = state.items
        .reduce((total, item) => total + item.net_amount, 0)
        .toFixed(2);
      state.subTotal = state.items.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );
    },

    // Decrement quantity of an item in the cart
    decrementQuantity: (state, action: PayloadAction<number>) => {
      const itemIdToDecrement = action.payload;
      const itemToDecrement = state.items.find(
        (item) => item.id === itemIdToDecrement
      );
      if (itemToDecrement && itemToDecrement.quantity > 1) {
        itemToDecrement.quantity -= 1;
        itemToDecrement.total =
          itemToDecrement.quantity * itemToDecrement.price;
        calculateTaxAmounts(itemToDecrement);
      }

      // Update total price
      state.totalPrice = state.items
        .reduce((total, item) => total + item.net_amount, 0)
        .toFixed(2);
      state.subTotal = state.items.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );
    },
    // Add single item to cart, or increment quantity if item exists
    addItem: (state, action: PayloadAction<CartItem>) => {
      const { id, price, cgst, sgst, igst } = action.payload;
      const existingItem = state.items.find((item) => item.id === id);

      if (existingItem) {
        existingItem.quantity += 1;
        existingItem.total = existingItem.quantity * price;
        calculateTaxAmounts(existingItem);
      } else {
        const newItem: CartItem = {
          ...action.payload,
          quantity: 1,
          total: price,
          cgst_amount: (price * cgst) / 100,
          sgst_amount: (price * sgst) / 100,
          igst_amount: (price * igst) / 100,
          net_amount:
            price +
            (price * cgst) / 100 +
            (price * sgst) / 100 +
            (price * igst) / 100,
        };
        state.items.push(newItem);
      }

      // Update total items and total price
      state.totalItems = state.items.reduce(
        (total, item) => total + item.quantity,
        0
      );
      state.totalPrice = state.items
        .reduce((total, item) => total + item.net_amount, 0)
        ?.toFixed(2);
      state.subTotal = state.items.reduce(
        (total, item) => total + item.price,
        0
      );
    },

    // Clear all items from cart
    clearCart: (state) => {
      state.items = [];
      state.totalItems = 0;
      state.totalPrice = 0;
      state.subTotal = 0;
    },
  },
});

// Extract action creators and reducer from cartSlice
export const {
  addToCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
  clearCart,
  addItem,
} = cartSlice.actions;

// Export reducer function for cartSlice
export default cartSlice.reducer;
