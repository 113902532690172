import React, { FC } from "react";
import Heading from "shared/Heading/Heading";
import FadeInUpOnScroll from "./Animation/FadeInUpOnScroll";

export interface TrendingProductProps {
  className?: string;
}

const TrendingProduct: FC<TrendingProductProps> = ({ className = "mb-1" }) => {
  return (
    <div className={`flex flex-col relative ${className}`}>
      <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
        <Heading>Trending Products</Heading>
      </FadeInUpOnScroll>
    </div>
  );
};

export default TrendingProduct;
