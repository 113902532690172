// import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
export const SweetToast = {
  success(msg) {
    return MySwal.fire({
      title: msg !== undefined ? msg : "",
      text: "",
      icon: "success",
    });
  },
  error(msg) {
    // console.log(msg, "msg");
    return MySwal.fire({
      icon: "error",
      title: "Oops...",
      text: msg !== undefined ? msg : "",
    });
  },
};
