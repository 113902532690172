import { _getImgRd, _getTagNameRd } from "contains/fakeData";
import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";

export interface SolutionCardProps {
  className?: string;
  size?: "large" | "normal";
  featuredImage?: string;
  name?: string;
  desc?: string;
}

const SolutionCard: FC<SolutionCardProps> = ({
  className = "",
  size = "large",
  name = "",

  featuredImage = "",
}) => {
  return (
    <NavLink
      to={"#"}
      className={`nc-CardCategory1  ${className}`}
      data-nc-id="CardCategory1"
    >
      <div className="flex items-center justify-center">
        <NcImage
          containerClassName={`flex-shrink-0 ${
            size === "large" ? "w-[180px] h-[180px]" : "w-12 h-12"
          } rounded-lg mr-4 overflow-hidden`}
          src={featuredImage || _getImgRd()}
        />
      </div>
      <div>
        <h2
          className={`${
            size === "large" ? "text-lg" : "text-base"
          } nc-card-title text-neutral-900 dark:text-neutral-100 font-semibold text-center mt-1`}
        >
          {name || _getTagNameRd()}
        </h2>
      </div>
    </NavLink>
  );
};

export default SolutionCard;
