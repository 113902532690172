import Heading from "components/Heading/Heading";
import React, { FC } from "react";
// import department1Png from "images/collections/p1.png";
// import department2Png from "images/collections/p2.png";
// import department3Png from "images/collections/p5.png";

import NewsArticleCard from "components/CardCategories/NewsArticleCard";
import FadeInUpOnScroll from "components/Animation/FadeInUpOnScroll";

export interface ProductCategoryProps {
  className?: string;
}
export interface CardCategoryData {
  name: string;
  img: string;
  Subheading?: string;
}
const ProductCategoryData: CardCategoryData[] = [
  {
    name: "MPs call for UK ban on two Chinese CCTV firms",

    img: "https://ichef.bbci.co.uk/news/1024/branded_news/8053/production/_119915823_gettyimages-1266205793.jpg",
    Subheading:
      "The world is an amazing place providing an incredible assortment of interesting locations across.",
  },
  {
    name: "Bullet CCTV Cameras",

    img: "https://resize.indiatvnews.com/en/resize/newbucket/400_-/2023/12/cctv-1-1701871179.jpg",
    Subheading:
      "The world is an amazing place providing an incredible assortment of interesting locations across.",
  },
  {
    name: "C-Mount CCTV Cameras",

    img: "https://www.sourcesecurity.com/img/news/612/nedap-adapts-to-change-the-dynamic-nature-of-retail-crime-920x533.jpg",
    Subheading: "The world is an amazing place providing .",
  },
];

export interface SectionSliderSolutionsProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: CardCategoryData[];
}

const SectionNewsArticle: FC<SectionSliderSolutionsProps> = ({
  data = ProductCategoryData,
}) => {
  return (
    <>
      <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
        <Heading>Latest news & articles</Heading>
      </FadeInUpOnScroll>
      <div className="grid grid-cols-12 gap-5">
        {data.map((item, index) => (
          <div
            className=" col-span-12  sm:col-span-6  md:col-span-6 lg:col-span-4"
            key={index}
          >
            <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
              <NewsArticleCard
                featuredImage={item.img}
                name={item.name}
                Subheading={item.Subheading}
              />
            </FadeInUpOnScroll>
          </div>
        ))}
      </div>
    </>
  );
};

export default SectionNewsArticle;
