import { defaultCompany } from "redux/Me/meSlice";

export const API_ENDPOINTS = {
  register: "register",
  login: "login",
  cms: "website/cms",
  Category: "get-category",
  PopularProduct: "get-popular-products",
  // itemMaster: "get-popular-products",
  itemMasterByID: "get-product",
  itemMaster: "get-products",
  Banner: "/herosection/get-all",
  Categories: "/category/get-all",
  ProductByCategory: "/getProductByCategory",
  ProductBySlug: "/getProductBySlug",
  PageBySlug: "/getPageBySlug",
  Settings: "/getSettingData",
  SalesOrder: "sales/sales-order",
  Me: "auth/me",
  hrUserAddress: "hr/UserAddress",
  defaultCompany: "setup/default-company",
};
