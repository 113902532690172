import { useRef, useEffect, useState } from "react";
import "./style.scss";

const FadeInUpOnScroll = ({
  children,
  className = "",
  defaultClassName = "",
  delay = "0s",
}: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef: any = useRef<any>(null);

  useEffect(() => {
    const observer: any = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.3, // Adjust this threshold as needed
      }
    );

    const currentElement = elementRef.current;

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [className]);

  return (
    <div
      ref={elementRef}
      style={{ animationDelay: delay }}
      className={`${defaultClassName} ${isVisible ? className : ""}`}
    >
      {children}
    </div>
  );
};

export default FadeInUpOnScroll;
