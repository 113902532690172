import React, { useEffect } from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { isAuthenticated } from "utils/isAuthenticated";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { AuthMe, defaultCompany } from "./redux/Me/meSlice";

function App() {
  // const MeData = useSelector(
  //   (state: RootState) => state.persistedReducer.me.meData
  // );
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(AuthMe()).then(() => {
        // console.log(MeData, "DDDDD");
      });
    }

    dispatch(defaultCompany());
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>Meet Solution</title>
        <meta name="description" content="Meet Solution" />
      </Helmet>

      {/* MAIN APP */}
      <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
        <MyRouter />
      </div>
    </HelmetProvider>
  );
}

export default App;
