import React from "react";

const Policy = () => {
  return (
    <>
      <div className="container bg-gray-100 mt-2">
        <div className="py-4">
          <div className="text-center p-4 bg-gradient-to-b from-blue-900 to-teal-400 text-white mb-1 rounded-lg shadow-md">
            <h3 className="text-3xl md:text-4xl font-light mb-1">
              Privacy and Policy
            </h3>
            <p>You trust, and we protect your privacy.</p>
          </div>
        </div>
        <div className="container mx-auto px-4 pb-4">
          <div className="bg-white rounded-lg shadow-lg p-6 md:p-8">
            <h5 className="text-xl font-semibold mb-3">Acceptance</h5>
            <p className="text-gray-700 mb-6 text-justify">
              This website is under the following terms and conditions.
               By accessing any page on the Meet Solution, you agree
              to be bound by these Terms and Conditions. These Terms and
              Conditions may be updated by the Meet Solution from time to time without
              prior notice. Please check the Terms and Conditions for updates
              each time you visit the Meet Solution.
            </p>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">
                Privacy Policy of Meet Solution
              </h5>
              <p className="text-gray-700 mb-6 text-justify">
                This privacy policy (“Policy”) outlines how Meet Solution
                collects, uses, and protects information from its customers,
                vendors, and other users (“Disclosing Party”). It specifies:
              </p>
              <ol className="list-inside list-disc text-gray-600 space-y-4">
                <li className="text-justify">
                  The types of information collected.
                </li>
                <li className="text-justify">
                  How the collected information is used.
                </li>
                <li className="text-justify">
                  Security measures adopted to protect this information.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">
                Information Collection
              </h5>
              <p className="text-gray-700 mb-6 text-justify">
                Meet Solution collects the following types of information
                from the Disclosing Party:
              </p>
              <ol className="list-inside list-disc text-gray-600 space-y-4">
                <li className="text-justify">
                  <strong className="font-medium">
                    Personal Information :
                  </strong>
                  Name, email address, phone number, and shipping address.
                </li>
                <li className="text-justify">
                  <strong className="font-medium">Payment Information:</strong>
                  Credit/debit card details and billing information.
                </li>
                <li className="text-justify">
                  <strong className="font-medium">Usage Data:</strong>{" "}
                  Information on how the Disclosing Party interacts with the
                  Meet Solution.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">Use of Information</h5>
              <p className="text-gray-700 mb-6 text-justify">
                The Meet Solution may use the collected information for the following
                purposes:
              </p>
              <ol className="list-inside list-disc text-gray-600 space-y-4">
                <li className="text-justify">
                  To process transactions and deliver products.
                </li>
                <li className="text-justify">
                  To communicate with the Disclosing Party about orders,
                  services, and promotions.
                </li>
                <li className="text-justify">
                  To improve customer service and enhance user experience.
                </li>
                <li>
                  For marketing purposes, including sending promotional emails
                  and newsletters.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">
                Information Sharing
              </h5>
              <p className="text-gray-700 mb-6 text-justify">
                Meet Solution may share the Disclosing Party's information
                with:
              </p>
              <ol className="list-inside list-disc text-gray-600 space-y-4">
                <li className="text-justify">
                  <strong className="font-medium">Service Providers:</strong>
                  Trusted third-party vendors who assist in operations (e.g.,
                  shipping companies, payment processors).
                </li>
                <li className="text-justify">
                  <strong className="font-medium">Legal Authorities:</strong>
                  When required by law or in response to legal requests.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">
                Storage of Information
              </h5>
              <ol className="list-inside list-disc text-gray-600 space-y-4">
                <li className="text-justify">
                  Information is stored on secure servers and protected with
                  reasonable safeguards as per applicable laws.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">
                Cross-Border Transfer
              </h5>
              <p className="text-gray-600 mb-2 text-justify">
                Information may be transferred to, stored, and processed in
                countries outside the Disclosing Party's country of residence.
                By providing information, the Disclosing Party consents to this
                transfer.
              </p>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">
                Access, Correction, and Profile Updates
              </h5>
              <p className="text-gray-600 mb-2 text-justify">
                The Disclosing Party may request to review, correct, or update
                their personal information by contacting [Your Contact
                Information].
              </p>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">
                Retention and Deletion
              </h5>
              <p className="text-gray-600 mb-2 text-justify">
                The Meet Solution will retain the Disclosing Party's information as
                long as necessary to fulfill the purposes outlined in this
                Policy or as required by law. Upon expiration of the retention
                period, the information will be securely deleted.
              </p>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">Security</h5>
              <p className="text-gray-600 mb-2 text-justify">
                Meet Solution employs physical, electronic, and
                administrative safeguards to protect the Disclosing Party's
                information from unauthorized access, use, or disclosure.
              </p>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">Information Shared by the Disclosing Party</h5>
              <p className="text-gray-600 mb-2 text-justify">
              The Disclosing Party is responsible for any information shared, including personal data and images. The Meet Solution is not liable for any unauthorized content shared by the Disclosing Party.
              </p>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">Dispute Resolution</h5>
              <p className="text-gray-600 mb-2 text-justify">
              For any complaints regarding this Policy, please contact info@meetsolution.in.
              </p>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">Sensitive Information</h5>
              <p className="text-gray-600 mb-2 text-justify">
              The Disclosing Party is advised not to provide sensitive information (e.g., credit card CVV, personal identification numbers).
              </p>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">Policy Updates</h5>
              <p className="text-gray-600 mb-2 text-justify">
              meet Solution may update this Policy periodically. Significant changes will be communicated prominently on the meet Solution.
              </p>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">Contact Us</h5>
              <p className="text-gray-600 mb-0 text-justify">
                If you have any questions, concerns, or requests regarding this
                Privacy Policy, please contact us at - E-mail on:
                info@meetsolution.in
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Policy;
