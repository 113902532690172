import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { token } from "api";
import { API_ENDPOINTS } from "api/apiEndPoints";
import axios from "axios";

export const AuthMe: any = createAsyncThunk(
  "/UserAuthMe",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}${API_ENDPOINTS.Me}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { meData: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const defaultCompany: any = createAsyncThunk(
  "/defaultCompany",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(
        `${baseUrl}${API_ENDPOINTS.defaultCompany}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return { defaultCompanyData: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const UpdateAdress: any = createAsyncThunk(
  "/UserUpdateAdress",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.put(
        `${baseUrl}${API_ENDPOINTS.hrUserAddress}/${data?.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return { UpdateAdressData: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const AddAdress: any = createAsyncThunk(
  "/UserAddAdress",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(
        `${baseUrl}${API_ENDPOINTS.hrUserAddress}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return { AddAdressData: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const MeDataSlice = createSlice({
  name: "MeData",
  initialState: {
    loadingMe: false,
    meData: [],
    defaultCompanyData: [],
    AddAdressData: [],
    isLoadingEditAddress: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AuthMe.pending, (state: any) => {
        state.loadingMe = true;
      })
      .addCase(AuthMe.fulfilled, (state: any, action: any) => {
        // console.log(action.payload, "meDatas");
        state.meData = action.payload.meData;
        state.loadingMe = false;
      })
      .addCase(AuthMe.rejected, (state: any) => {
        state.loadingMe = false;
      })
      .addCase(UpdateAdress.pending, (state: any) => {
        state.isLoadingEditAddress = true;
      })
      .addCase(UpdateAdress.fulfilled, (state: any, action: any) => {
        state.UpdateAdressData = action.payload.UpdateAdressData;
        state.isLoadingEditAddress = false;
      })
      .addCase(AddAdress.fulfilled, (state: any, action: any) => {
        state.AddAdressData = action.payload.AddAdressData;
        state.isLoadingEditAddress = false;
      })
      .addCase(UpdateAdress.rejected, (state: any) => {
        state.isLoadingEditAddress = false;
      })
      .addCase(defaultCompany.fulfilled, (state: any, action: any) => {
        state.defaultCompanyData = action.payload.defaultCompanyData;
        // state.loadingMe = false;
      });
  },
});

export default MeDataSlice.reducer;
