import React, { useState } from "react";

const DeleteAccount: React.FC = () => {
  const [password, setPassword] = useState("");
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleDeleteAccount = () => {
    console.log("Account deleted");
  };

  // const toggleSidebar = () => {
  //   setIsSidebarOpen(!isSidebarOpen);
  // };

  return (
    <div className="flex justify-center items-center mb-4 mt-4">
      {/* Toggle button for mobile */}
      {/* <button
        className="md:hidden bg-blue-600 text-white p-2 rounded-md mb-4"
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? 'Close Menu' : 'Open Menu'}
      </button> */}

      {/* Left-side menu (sidebar) */}
      {/* <nav
        className={`text-sm px-2 transform md:translate-x-0 transition-transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } md:block fixed md:relative top-0 left-0 w-2/3 md:w-auto bg-white h-full md:h-auto md:bg-transparent z-50`}
      >
        <ul className="md:space-y-4 mt-6 md:mt-0">
          <li className="mb-1">
            <a href="#" className="text-blue-600 hover:underline">
              Settings
            </a>
          </li>
          <li className="mb-1">
            <a href="#" className="text-blue-600 hover:underline">
              Password
            </a>
          </li>
          <li className="mb-1">
            <a href="#" className="text-blue-600 hover:underline">
              View profile
            </a>
          </li>
          <li className="mb-1">
            <a href="#" className="text-blue-600 hover:underline">
              Logout
            </a>
          </li>
          <li className="mb-1">
            <a href="#" className="text-blue-600">
              Delete account
            </a>
          </li>
        </ul>
      </nav> */}

      {/* Delete account section */}
      <div className="w-3/4 pl-6">
        <h1 className="text-2xl font-bold mb-4">Delete account</h1>
        <form className="space-y-4">
          <div>
            <label htmlFor="password" className="block font-medium">
              Current password <span className="text-red-500">*</span>
            </label>
            <input
              type="password"
              id="password"
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter your current password"
              value={password}
              onChange={handlePasswordChange}
            />
            <p className="text-sm text-gray-500 mt-2">
              Enter your current password to confirm cancellation of your
              account.
            </p>
          </div>
          <button
            type="button"
            onClick={handleDeleteAccount}
            className="bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800"
          >
            Delete account
          </button>
        </form>
      </div>
    </div>
  );
};

export default DeleteAccount;
