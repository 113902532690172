import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NewsArticleCard from "components/CardCategories/NewsArticleCard";
import { Link } from "react-router-dom";

export interface BlogPageProps {
  className?: string;
  data?: CardCategoryData[];
}
export interface CardCategoryData {
  name: string;
  img: string;
  Subheading?: string;
}
const ProductCategoryData: CardCategoryData[] = [
  {
    name: "MPs call for UK ban on two Chinese CCTV firms",

    img: "https://ichef.bbci.co.uk/news/1024/branded_news/8053/production/_119915823_gettyimages-1266205793.jpg",
    Subheading:
      "The world is an amazing place providing an incredible assortment of interesting locations across.",
  },
  {
    name: "Bullet CCTV Cameras",

    img: "https://resize.indiatvnews.com/en/resize/newbucket/400_-/2023/12/cctv-1-1701871179.jpg",
    Subheading:
      "The world is an amazing place providing an incredible assortment of interesting locations across.",
  },
  {
    name: "C-Mount CCTV Cameras",

    img: "https://www.sourcesecurity.com/img/news/612/nedap-adapts-to-change-the-dynamic-nature-of-retail-crime-920x533.jpg",
    Subheading: "The world is an amazing place providing .",
  },
];

const BlogPage: FC<BlogPageProps> = ({
  className = "",
  data = ProductCategoryData,
}) => {
  return (
    <div
      className={`nc-PageCollection ${className}`}
      data-nc-id="PageCollection"
    >
      <Helmet>
        <title>Our Product </title>
      </Helmet>

      <div className="container py-5 lg:pb-10 lg:pt-10 ">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="max-w-screen-sm">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              Blog and News
            </h2>
          </div>

          <hr className="border-slate-200 dark:border-slate-700" />
          <main>
            <div className="grid grid-cols-12 gap-5">
              {data.map((item, index) => (
                <div
                  className=" col-span-12  sm:col-span-6  md:col-span-6 lg:col-span-4"
                  key={index}
                >
                  <Link to={"/blog-single"}>
                    <NewsArticleCard
                      featuredImage={item.img}
                      name={item.name}
                      Subheading={item.Subheading}
                    />
                  </Link>
                </div>
              ))}
            </div>

            <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
              <Pagination />
              <ButtonPrimary loading>Show me more</ButtonPrimary>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
