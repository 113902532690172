import { useQuery } from "@tanstack/react-query";
import { baseUrl, token } from "api";
import { API_ENDPOINTS } from "../apiEndPoints";
import axios from "axios";

const fetchCMS = async () => {
  const response = await axios.get(`${baseUrl}${API_ENDPOINTS.cms}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const useCMSData = () => {
  const { data: cmsData, isLoading } = useQuery({
    queryKey: ["cms"], // Adjust queryKey as per your API requirements
    queryFn: fetchCMS,
    staleTime: 1000 * 60 * 5, // Adjust staleTime as needed
  });

  return { cmsData, isLoading };
};

export default useCMSData;
