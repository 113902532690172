import { useQuery } from "@tanstack/react-query";
import { baseUrl, token } from "api";
import { API_ENDPOINTS } from "../apiEndPoints";
import axios from "axios";

const fetchPopularProduct = async () => {
  const response = await axios.get(
    `${baseUrl}${API_ENDPOINTS.PopularProduct}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const usePopularProductData = () => {
  const { data: PopularProductData, isLoading } = useQuery({
    queryKey: ["PopularProduct"], // Adjust queryKey as per your API requirements
    queryFn: fetchPopularProduct,
    staleTime: 1000 * 60 * 5, // Adjust staleTime as needed
  });

  return { PopularProductData, isLoading };
};

export default usePopularProductData;
