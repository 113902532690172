import React, { FC, Fragment, useState } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import AvatarDropdown from "./AvatarDropdown";
import Navigation from "shared/Navigation/Navigation";
import CartDropdown from "./CartDropdown";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
// import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "utils/isAuthenticated";
// import Button from "shared/Button/Button";
import { Link } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Checkbox from "shared/Checkbox/Checkbox";

export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [showSearchForm, setShowSearchForm] = useState(false);
  // const navigate = useNavigate();

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  const renderSupportIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="38"
        fill="none"
        viewBox="0 0 36 38"
      >
        <g clipPath="url(#clip0_146_1986)">
          <g clipPath="url(#clip1_146_1986)">
            <path
              fill="#253D4E"
              d="M4.5 19.636V17.5a13.5 13.5 0 0127 0v2.136A7.5 7.5 0 0128.5 34h-3V19h3v-1.5a10.5 10.5 0 10-21 0V19h3v12h6v3h-9a7.5 7.5 0 01-3-14.364zM28.5 31a4.5 4.5 0 100-9v9zm-21 0v-9a4.5 4.5 0 100 9z"
            ></path>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_146_1986">
            <path
              fill="#fff"
              d="M0 0H36V38H0z"
              transform="matrix(-1 0 0 1 36 0)"
            ></path>
          </clipPath>
          <clipPath id="clip1_146_1986">
            <path
              fill="#fff"
              d="M0 0H36V36H0z"
              transform="matrix(-1 0 0 1 36 1)"
            ></path>
          </clipPath>
        </defs>
      </svg>
    );
  };
  // const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  // const openModalMoreFilter = () => setisOpenMoreFilter(true);
  const [colorsState, setColorsState] = useState<string[]>([]);

  const DATA_colors = [
    { name: "Dome Cameras" },
    { name: "Bullet Cameras" },
    { name: "PTZ (Pan-Tilt-Zoom) Cameras" },
    { name: "Box Cameras" },
    { name: "Cube Cameras" },
    { name: "Hidden Cameras" },
    { name: "Navy" },
  ];
  const handleChangeColors = (checked: boolean, name: string) => {
    checked
      ? setColorsState([...colorsState, name])
      : setColorsState(colorsState.filter((i) => i !== name));
  };
  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };
  const renderTabsColor = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={` flex items-center justify-center px-4 py-2 text-sm   focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  !!colorsState.length
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <span className="">Select Category</span>
              {!colorsState.length ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => setColorsState([])}>{renderXClear()}</span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {DATA_colors.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={colorsState.includes(item.name)}
                          onChange={(checked: any) =>
                            handleChangeColors(checked, item.name)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-slate-50 dark:bg-slate-900 dark:border-t dark:border-slate-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setColorsState([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };
  const renderSearchForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="flex-1 py-2 text-slate-900 dark:text-slate-100 "
      >
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center border    h-full rounded">
          {renderTabsColor()}
          {renderMagnifyingGlassIcon()}
          <input
            ref={inputRef}
            type="text"
            placeholder="Type and press enter"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-80 text-base"
            autoFocus
          />
          <button
            type="button"
            className="ml-auto" // Add ml-auto class here for margin-left:auto
            onClick={() => setShowSearchForm(false)}
          >
            <XMarkIcon className="w-5 h-5 mr-2" />
          </button>
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div className="h-16 flex justify-between">
          <div className="flex items-center lg:hidden flex-1">
            <MenuBar />
          </div>

          <div className="lg:flex-1 flex items-center">
            <Logo className="flex-shrink-0" />
          </div>

          <div className="flex-[2] hidden lg:flex justify-center mx-4">
            {showSearchForm ? renderSearchForm() : ""}
          </div>

          <div className="flex-1 flex items-center justify-end text-slate-700 dark:text-slate-100">
            {!showSearchForm && (
              <button
                className="hidden lg:flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center"
                onClick={() => setShowSearchForm(!showSearchForm)}
              >
                {renderMagnifyingGlassIcon()}
              </button>
            )}
            {isAuthenticated() ? (
              <>
                <AvatarDropdown />
              </>
            ) : (
              <>
                <Link to={"/login"}>
                  <button className=" mx-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white  px-3 border border-blue-500 hover:border-transparent rounded">
                    Login
                  </button>
                </Link>
                {/* <Button
                  className="mx-2 bg-primary-500 px-2 text-white"
                  sizeClass="small"
                >
                  Login
                </Button> */}
              </>
            )}
            <CartDropdown />
          </div>
        </div>
        <div className=" hidden  h-16 lg:flex justify-between">
          <div className="flex-[2] hidden lg:flex ">
            <Navigation />
          </div>
          <div className="flex gap-2">
            {renderSupportIcon()}
            <h1 className="text-[#1565B1] text-lg  font-bold">
              +91 1234567890 <br></br>
              <span className="text-xs text-gray-500 ml-2 ">
                {" "}
                24/7 Support Center
              </span>
            </h1>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
      <div className="container ">{renderContent()}</div>
    </div>
  );
};

export default MainNav2Logged;
