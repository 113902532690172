import FadeInUpOnScroll from "components/Animation/FadeInUpOnScroll";
import React, { FC } from "react";

export interface ProductCategoryProps {
  className?: string;
}
export interface CardCategoryData {
  name: string;
  img: any;
  Subheading?: string;
}
const DeliveryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="28"
      fill="none"
      viewBox="0 0 33 28"
    >
      <path
        stroke="#0989FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.723 1.98h20.833v18.056H10.723V1.98zM10.722 8.924H5.167L1 13.091v6.945h9.722V8.924z"
      ></path>
      <path
        stroke="#0989FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M25.305 26.98a3.472 3.472 0 110-6.945 3.472 3.472 0 010 6.945zM7.25 26.98a3.472 3.472 0 110-6.944 3.472 3.472 0 010 6.944z"
      ></path>
    </svg>
  );
};
const Icon2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="35"
      fill="none"
      viewBox="0 0 21 35"
    >
      <path
        stroke="#FD4B6B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.364 1v33M17.864 7H6.614a5.25 5.25 0 100 10.5h7.5a5.25 5.25 0 110 10.5H1.364"
      ></path>
    </svg>
  );
};
const Icon3 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      fill="none"
      viewBox="0 0 31 31"
    >
      <path
        stroke="#FD4B6B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1.5 24.813V15.48a14 14 0 0128 0v9.333"
      ></path>
      <path
        stroke="#FD4B6B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M29.5 26.369a3.111 3.111 0 01-3.111 3.111h-1.556a3.111 3.111 0 01-3.11-3.111v-4.667a3.111 3.111 0 013.11-3.11H29.5v7.777zm-28 0a3.111 3.111 0 003.111 3.111h1.556a3.111 3.111 0 003.11-3.111v-4.667a3.111 3.111 0 00-3.11-3.11H1.5v7.777z"
      ></path>
    </svg>
  );
};
const ProductCategoryData: CardCategoryData[] = [
  {
    name: "Free Delivery*",

    img: <DeliveryIcon />,
    Subheading: "Orders from all item",
  },
  {
    name: "Return & Refund",

    img: <Icon2 />,
    Subheading: "Money back guarantee",
  },
  {
    name: "Support 24/7",

    img: <Icon3 />,
    Subheading: "Contact us 24 hours a day",
  },
];

export interface SectionSliderSolutionsProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: CardCategoryData[];
}

const SectionSupport: FC<SectionSliderSolutionsProps> = ({
  data = ProductCategoryData,
}) => {
  return (
    <>
      <div className="grid grid-cols-12 gap-5">
        {data.map((item, index) => (
          <div
            className=" col-span-12  sm:col-span-6  md:col-span-6 lg:col-span-4"
            key={index}
          >
            <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
              <div className="bg-[#F6F7F9] h-[100px] flex items-center ">
                <div className="grid grid-cols-12 px-5">
                  <div className="col-span-4">{item.img}</div>
                  <div className="col-span-8">
                    {item.name}
                    <h2 className="text-sm text-grey-500">
                      {" "}
                      {item.Subheading}{" "}
                    </h2>
                  </div>
                </div>
              </div>
            </FadeInUpOnScroll>
          </div>
        ))}
      </div>
    </>
  );
};

export default SectionSupport;
