import { useQuery } from "@tanstack/react-query";
import { baseUrl, token } from "api";
import { API_ENDPOINTS } from "../apiEndPoints";
import axios from "axios";

const fetchItemMaster = async ({ queryKey }: any) => {
  const page = queryKey[1]?.page ?? 1;
  const response = await axios.get(
    `${baseUrl}${API_ENDPOINTS.itemMaster}?page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
const fetchItemMasterByID = async ({ queryKey }: any) => {
  const id = queryKey[1] ?? "";
  const response = await axios.get(
    `${baseUrl}${API_ENDPOINTS.itemMasterByID}/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const useItemMasterData = (payLoad: any) => {
  const { data: ItemMasterData, isLoading } = useQuery({
    queryKey: ["ItemMaster", payLoad], // Pass payload as part of queryKey
    queryFn: fetchItemMaster, // Pass the function reference
    staleTime: 1000 * 60 * 5, // Adjust staleTime as needed
  });

  return { ItemMasterData, isLoading };
};
export const useItemMasterDataById = (id: any) => {
  const { data: ItemMasterDataById, isLoading } = useQuery({
    queryKey: ["ItemMasterById", id], // Pass payload as part of queryKey
    queryFn: fetchItemMasterByID, // Pass the function reference
    staleTime: 1000 * 60 * 5, // Adjust staleTime as needed
  });

  return { ItemMasterDataById, isLoading };
};
